<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogSurveyRemovePendingStatus" />
  </div>
</template>
<script>
import patsService from "../services/pats.service";

export default {
  name: "SurveyRemovePendingStatus",
  props: ["removePendingdStatus", "showToast", "patId", "patName"],
  watch: {
    removePendingdStatus() {
      if (this.removePendingdStatus === true) {
        this.confirmRemovePending();
      }
    }
  },
  methods: {
    confirmRemovePending() {
      let title = `Retirar survey de pendente?`;
      let message = `Tem a certeza que pretende remover o pat ${this.patId} - ${this.patName} do estado de pendente?`;

      this.$confirm.require({
        key: "dialogSurveyRemovePendingStatus",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.removeThePending();
        },
        reject: () => {
          return this.$emit("cancel");
        }
      });
    },
    removeThePending() {
      return patsService.removePending(this.patId).then(response => {
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao mudar o estado do survey`,
              detail: "",
              life: 3000
            });
          }
          return this.$emit("removePending", {
            status: "error",
            currentStatus: {}
          });
        }
        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `O survey mudou o estado para em progresso`,
            detail: "",
            life: 3000
          });
        }
        return this.$emit("removePending", {
          status: "ok",
          currentStatus: {
            patStatus: "1. Em Progresso",
            surveyStatus: "Por Marcar",
            surveyLastComment: null
          }
        });
      });
    }
  }
};
</script>
