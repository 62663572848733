<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogSurveySetClosedStatus" :closable="false" />
  </div>
</template>
<script>
import patsService from "../services/pats.service";

export default {
  name: "SurveySetClosedStatus",
  props: ["setClosedStatus", "showToast", "patId", "patName", "patService"],
  watch: {
    setClosedStatus() {
      if (this.setClosedStatus === true) {
        this.confirmConlued();
      }
    }
  },
  methods: {
    confirmConlued() {
      let message = `Tem a certeza que pretende concluir ${this.patService}
      do Pat ${this.patId} - ${this.patName}?`;
      this.$confirm.require({
        key: "dialogSurveySetClosedStatus",
        header: `Concluir ${this.patService}`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.closePat();
        },
        reject: () => {
          return this.$emit("cancel");
        },
        discard: () => {
          return this.$emit("cancel");
        }
      });
    },
    closePat() {
      return patsService.closePat(this.patId).then(response => {
        if (!response || response.closed != 1) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao concluir ${this.patService}`,
              detail: "",
              life: 3000
            });
          }
          return this.$emit("setClosed", {
            status: "error",
            currentStatus: {}
          });
        }

        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `${this.patService} concluido`,
            detail: `${this.patService} com o pat ${this.patId}
            foi concluido`,
            life: 3000
          });
        }
        return this.$emit("setClosed", {
          status: "ok",
          currentStatus: {
            patStatus: "3. Concluído",
            surveyStatus: "Concluido"
          }
        });
      });
    }
  }
};
</script>
