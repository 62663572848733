<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Responsável pelo Orçamento'"
      :modal="true"
      :closable="false"
    >
      <form
        name="SurveyChangeBudgetResponsible"
        class="p-col-12"
        @submit.prevent="saveResponsible"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="userToDoOpt"
                :options="activeUsers"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'id'"
                v-model="responsibleId"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('userToDoOpt') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Selecionar Funcionário</label>
            </span>
            <small
              v-if="errors.has('userToDoOpt')"
              class="p-error"
              role="alert"
            >
              Funcionário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveResponsible"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import patsService from "../services/pats.service";
import usersService from "../services/user.service";
import unidecode from "unidecode";
export default {
  name: "SurveyChangeBudgetResponsible",
  props: ["showToast", "show", "currentResponsibleId", "patId"],
  watch: {
    currentResponsibleId() {
      this.$validator.pause();
      this.$validator.reset();
      this.responsibleId = this.currentResponsibleId;
    },
  },
  data() {
    return {
      activeUsers: [],
      responsibleId: this.currentResponsibleId
    };
  },
  created() {
    this.getActiveUsers();
  },
  methods: {
    getSearchLabel(info) {
      return `${unidecode(info.username)}`
    },
    getActiveUsers() {
      return usersService
        .activesUsers()
        .then((response) => (this.activeUsers = response));
    },
    cancel() {
      this.$validator.pause();
      this.$validator.reset();
      return this.$emit("cancel");
    },
    saveResponsible() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return patsService
          .setBudgetUserToDo(this.patId, this.responsibleId)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o responsável pelo orçamento",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("setSurveyBudgetResponsible", {
                status: "error",
                currentResponsible: this.responsibleId,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Responsável pelo orçamento alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("setSurveyBudgetResponsible", {
              status: "ok",
              currentResponsible: {
                id: this.responsibleId,
                name: response.userTodo,
              },
            });
          });
      });
    },
  },
};
</script>
