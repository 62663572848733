<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogSurveySetConcluedStatus" />
  </div>
</template>
<script>
import patsService from "../services/pats.service";

export default {
  name: "SurveySetConcluedStatus",
  props: ["setConcluedStatus", "showToast", "patId", "patName", "patService"],
  watch: {
    setConcluedStatus() {
      if (this.setConcluedStatus === true) {
        this.confirmRealized();
      }
    }
  },
  methods: {
    confirmRealized() {
      let message = `Tem a certeza que pretende marcar como concluido o surveys
      do Pat ${this.patId} - ${this.patName}?`;
      this.$confirm.require({
        key: "dialogSurveySetConcluedStatus",
        header: `Marcar survey como realizado`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.realizadPat();
        },
        reject: () => {
          return this.$emit("cancel");
        }
      });
    },
    realizadPat() {
      const status = "2. Realizado";
      return patsService
        .updatedStatus(this.patId, { status: status })
        .then(response => {
          if (!response || response.status != status) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: `Erro ao marcar como realizado o survey`,
                detail: "",
                life: 3000
              });
            }
            return this.$emit("setConclued", {
              status: "error",
              currentStatus: {}
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `${this.patService} realizado`,
              detail: `${this.patService} com o pat ${this.patId} mudou o estado para realizado`,
              life: 3000
            });
          }
          return this.$emit("setConclued", {
            status: "ok",
            currentStatus: {
              patStatus: status,
              surveyStatus: "Sem Relatório"
            }
          });
        });
    }
  }
};
</script>
