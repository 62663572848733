<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :closable="false"
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Relatório'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="SurveyAddReport"
        class="p-col-12"
        @submit.prevent="addReport"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <p><b>Nome do survey: </b>{{ patName }}</p>
            <FileUpload
              ref="uploadInput"
              mode="basic"
              name="files[]"
              chooseLabel="Adicionar Relatório"
              v-validate="solution == '' ? 'required' : ''"
            />
          </div>
          <div class="p-field p-col-12 p-md-12">OU</div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label p-mt-1">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="solution"
                v-validate="solutionAreRequired ? 'required' : ''"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('survey-solution')
                  },
                  'form-control'
                ]"
                :name="'survey-solution'"
              />
              <label for="survey-solution">Escrever Relatório</label>
            </span>
          </div>
          <small
            v-if="errors.has('survey-solution') || errors.has('files[]')"
            class="p-error"
            role="alert"
          >
            É obrigatório anexar um ficheiro OU inserir o relatório manualmente
          </small>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="addReport"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import patsService from "../services/pats.service";
import filesService from "../services/files.service";
export default {
  name: "SurveyAddReport",
  props: ["showToast", "show", "patId", "patName", "patReference"],
  data() {
    return {
      solution: ""
    };
  },
  methods: {
    solutionAreRequired() {
      if (this.$refs.uploadInput.files.length == 0) {
        return true;
      }

      return false;
    },
    cancel() {
      this.solution = "";
      return this.$emit("cancel");
    },
    addReport() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid && this.$refs.uploadInput.files.length == 0) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.$refs.uploadInput.files.length > 0) {
          return this.uploadReportFile();
        }
        if (this.solution != "") {
          this.setSolutions();
        }
      });
    },
    setSolutions() {
      return patsService
        .setSolution(this.patId, { solution: this.solution })
        .then(response => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao inserir o relatório",
                life: 3000
              });
            }
            this.solution = "";
            return this.$emit("addedReport", {
              status: "error",
              currentStatus: {}
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: "Relatório inserido",
              detail: "O relatório inserido com sucesso",
              life: 3000
            });
          }
          return this.$emit("addedReport", {
            status: "ok",
            currentStatus: {
              surveyStatus: "Por Concluir"
            }
          });
        });
    },
    uploadReportFile() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadInput.files[0]);
      formData.append("origin", "PA");
      formData.append("origin_reference", this.patReference);

      return filesService.addFile(formData).then(response => {
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o relatório",
              life: 3000
            });
          }
          return this.$emit("addedReport", {
            status: "error",
            currentStatus: {}
          });
        }
        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: "Relatório inserido",
            detail: "O relatório inserido com sucesso",
            life: 3000
          });
        }
        return this.$emit("addedReport", {
          status: "ok",
          currentStatus: {
            surveyStatus: "Por Concluir"
          }
        });
      });
    }
  }
};
</script>
