<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Cancelar Survey'"
      :modal="true"
      :closable="false"
    >
      <form
        name="surveyCancelForm"
        class="p-col-12"
        @submit.prevent="cancelSurvey"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <p><b>Pat:</b> {{ patId }}</p>
            <p><b>Nome:</b> {{ patName }}</p>
            <span class="p-float-label">
              <Textarea
                name="comment"
                :autoResize="true"
                v-model="comment"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('comment') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="comment">Comentário</label>
            </span>
            <small v-if="errors.has('comment')" class="p-error" role="alert">
              Comentário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="cancelSurvey"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import surveyService from "../services/surveys.service";

export default {
  name: "SurveySetCancelled",
  props: ["show", "showToast", "patId", "patName"],
  data() {
    return {
      comment: null,
    };
  },
  methods: {
    cancel() {
      this.comment = null;
      return this.$emit("cancel");
    },
    cancelSurvey() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = { solution: this.comment };
        return surveyService
          .cancelSurvey(this.patId, bodyParams)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: `Erro ao cancelar o survey`,
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("surveyCanceled", {
                status: "error",
                currentStatus: {},
              });
            }
            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Survey Cancelado`,
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("surveyCanceled", {
              status: "ok",
              currenStatus: response.status,
            });
          });
      });
    },
  },
};
</script>
