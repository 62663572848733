<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogProposalSetBudget" />
  </div>
</template>
<script>
import proposalService from "../../services/proposal.service";

export default {
  name: "ProposalSetBudget",
  props: [
    "changeBudgetedStatus",
    "budgeted",
    "proposalId",
    "proposalName",
    "showToast",
  ],
  watch: {
    changeBudgetedStatus() {
      if (this.changeBudgetedStatus === true) {
        return this.confirmBudget();
      }
    },
  },
  methods: {
    confirmBudget() {
      let message = "";
      let title = "";
      if (this.budgeted != 1) {
        message = `Tem a certeza que pretende colocar a
        proposta ${this.proposalId} - ${this.proposalName} como orçamentada?`;
        title = `Colocar proposta como orçamentada?`;
      } else {
        message = `Tem a certeza que pretende colocar a
        proposta ${this.proposalId} - ${this.proposalName} como NÃO orçamentada?`;
        title = `Colocar proposta como NÃO orçamentada?`;
      }

      this.$confirm.require({
        key: "dialogProposalSetBudget",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.changeStatus();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    changeStatus() {
      return proposalService.toggleBudget(this.proposalId).then((response) => {
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao mudar o estado do orçamento`,
              detail: "",
              life: 3000,
            });
          }
          return this.$emit("setBudgetProposal", {
            status: "error",
            currentStatus: this.budgeted,
          });
        }

        let detail = "";
        if (response.budgeted === 0) {
          detail = `A proposta ${this.proposalId}
            mudou o estado para NÃO orçamentado`;
        } else {
          detail = `A proposta ${this.proposalId}
            mudou o estado para orçamentado`;
        }
        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `Alteração do estado orçamento`,
            detail: detail,
            life: 3000,
          });
        }
        if (response.budgeted == 1) {
          let message = "Quer acompanhar a proposta no CRM?";
          let title = "Deseja que seja adicionada esta proposta ao CRM?";

          this.$confirm.require({
            key: "dialogProposalSetBudget",
            header: title,
            message: message,
            icon: "pi pi-question-circle",
            acceptLabel: "Sim",
            acceptIcon: "pi pi-check",
            acceptClass: "p-button-success p-button p-component",
            rejectLabel: "Não",
            rejectIcon: "pi pi-times",
            rejectClass: "p-button-danger p-button p-component",
            accept: () => {
              return this.addToCRM(response.budgeted);
            },
            reject: () => {
              return this.$emit("setBudgetProposal", {
                status: "ok",
                currentStatus: response.budgeted,
              });
            },
          });
        }
      });
    },
    addToCRM(budgetResponse) {
      return proposalService.addToCRM(this.proposalId).then((response) => {
        this.$emit("setBudgetProposal", {
          status: "ok",
          currentStatus: budgetResponse,
        });
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao adicionar a proposta ao CRM`,
              detail: "",
              life: 3000,
            });
          }
          return this.$emit("addedToCrm", {
            status: "error",
          });
        }

        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `Proposta adicionada ao CRM`,
            detail: `A proposta ${this.proposalId} foi adicionada ao CRM com o id W4M_${response.id}`,
            life: 3000,
          });
        }

        return this.$emit("addedToCrm", {
          status: "ok",
          crmProposal: response,
        });
      });
    },
  },
};
</script>
