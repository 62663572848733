<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Colocar Survey como Pendente'"
      :modal="true"
      :closable="false"
    >
      <form
        name="changeTopending"
        class="p-col-12"
        @submit.prevent="setPendingStatus"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <p><b>Pat:</b> {{ patId }}</p>
            <p><b>Nome:</b> {{ patName }}</p>
            <span class="p-float-label">
              <Textarea
                name="comment"
                :autoResize="true"
                v-model="comment"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('comment') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component'
                ]"
                rows="5"
              />
              <label for="comment">Comentário</label>
            </span>
            <small v-if="errors.has('comment')" class="p-error" role="alert">
              Comentário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setPendingStatus"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import patsService from "../services/pats.service";
export default {
  name: "SurveySetPendingStatus",
  props: ["showToast", "show", "patId", "patName"],
  data() {
    return {
      comment: null
    };
  },
  methods: {
    cancel() {
      this.comment = null;
      return this.$emit("cancel");
    },
    setPendingStatus() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = { message: this.comment };
        return patsService.setPending(this.patId, bodyParams).then(response => {
          this.comment = null;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: `Erro ao mudar o estado do survey`,
                detail: "",
                life: 3000
              });
            }
            return this.$emit("setSurveyPending", {
              status: "error",
              message: {}
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `O survey mudou o estado para pendente`,
              detail: "",
              life: 3000
            });
          }
          return this.$emit("setSurveyPending", {
            status: "ok",
            message: {
              patStatus: "2. Pendente",
              surveyStatus: "Pendente",
              surveyLastComment: response
            }
          });
        });
      });
    }
  }
};
</script>
